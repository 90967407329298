import Config from './Config';

const parseValue = (value) => {
  if (value === '0' || value === 'OFF' || value === 'false') {
    return false;
  }
  return true;
};

const getFlag = (key, defaultValue) => {
  const params = new URLSearchParams(window.location.search);
  if (params.has(key)) {
    return parseValue(params.get(key));
  }
  return defaultValue;
};

const isTest = () => {
  // @ts-ignore
  return import.meta.env.NODE_ENV === 'test' || import.meta.env.VITE_APP_IS_E2E;
};

export const isProduction = () => {
  return Config.VITE_CI_DEPLOYMENT_ENVIRONMENT === 'production';
};

const isLocalHost = () => {
  return window.location.host.includes('localhost');
};

const getSentryEnabled = () => {
  if (isTest()) {
    return false;
  }
  if (isLocalHost()) {
    return false;
  }
  return getFlag('SENTRY_ENABLED', true);
};

const getMatomo = () => {
  if (isTest()) {
    return false;
  }
  if (isLocalHost()) {
    return false;
  }
  return getFlag('MATOMO', true);
};

const getGoogleBot = () => {
  const flag = getFlag('I_AM_GOOGLE_BOT', false);
  if (flag) {
    return true;
  }
  const botPattern =
    '(googlebot/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)';
  const re = new RegExp(botPattern, 'i');
  const { userAgent } = navigator;
  if (userAgent.toLowerCase().includes('Lighthouse')) {
    return true;
  }
  return re.test(userAgent);
};

export default {
  CARD_CREATE_THEN_EDIT: getFlag('CARD_CREATE_THEN_EDIT', true),
  BOARD_LAYOUT_LEFT: getFlag('BOARD_LAYOUT_LEFT', true),
  BOARD_BACKGROUND: getFlag('BOARD_BACKGROUND', true),
  CARD_BACKGROUND: getFlag('CARD_BACKGROUND', true),
  HEADER_SEARCH: getFlag('HEADER_SEARCH', true),
  BOARD_PASSWORD: getFlag('BOARD_PASSWORD', true),
  HEADER_MOBILE: getFlag('HEADER_MOBILE', true),
  CONTENT_EDITOR_QUILL: getFlag('CONTENT_EDITOR_QUILL', true),
  PROJECTS_SORT: getFlag('PROJECTS_SORT', true),
  MATOMO: getMatomo(),
  SLIDE_SHOW_CLOSE_BUTTON: getFlag('SLIDE_SHOW_CLOSE_BUTTON', true),
  OPEN_GRAPH_DESCRIPTION: false,
  CARD_AUTO_OPEN_MODAL: true,
  BOARD_DEBUG_LOGS: getFlag('BOARD_DEBUG_LOGS', false),
  BOARD_FULL_SCROLL: getFlag('BOARD_FULL_SCROLL', true),
  BOARD_TRASH: true,
  CARD_SCHEDULING: getFlag('CARD_SCHEDULING', true),
  SENTRY_ENABLED: getSentryEnabled(),
  CARD_DUPLICATE: getFlag('CARD_DUPLICATE', true),
  EMBED_CONSENT_POPUP: getFlag('EMBED_CONSENT_POPUP', true),
  SOURCE_CODE_LINK: getFlag('SOURCE_CODE_LINK', true),
  CARD_DESCRIPTION_FOCUS_VIA_TAB: getFlag('CARD_DESCRIPTION_FOCUS_VIA_TAB', true),
  DESCRIPTION_DIALOG_CANCEL: getFlag('DESCRIPTION_DIALOG_CANCEL', true),
  NEW_BOARD_IS_PUBLIC_HINT: getFlag('NEW_BOARD_IS_PUBLIC_HINT', true),
  CUSTOM_IMAGE_UPLOAD: getFlag('CUSTOM_IMAGE_UPLOAD', false),
  NEW_BOARD_EDIT_SETTINGS: getFlag('NEW_BOARD_EDIT_SETTINGS', false),
  IS_GOOGLE_BOT: getGoogleBot(),
};
